.x-table {
    margin: 10px;
   }
   
.x-content-table {
    position: relative;
    border: 1px solid #28303F;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 8px;
}

.x-col {
    display: flex;
    justify-content:center;
    align-items:center;
}

.x-col-left {
    display: flex;
    justify-content:left;
    align-items:center;
}

.x-col-right {
    display: flex;
    justify-content:right;
    align-items:center;
}

.x-col .image {
    width: 70px;
    height: 70px;
    border-radius: 12px;
    overflow: hidden;
}

.x-text {
    font-size: 18px;
}

.x-head-table-text {
    font-size: 19px;
    font-weight: bold;
}


.x-chain-icon {
    border-radius: 20px;
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    background-color: #3749E9;
    align-items: center;
    margin: 0 auto 10px;
    overflow: hidden;
    position: relative;
}

.x-chain-icon img {
    border-radius: inherit;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
    object-fit: cover;
}

.x-mintButton {
    border-radius: 7px;
    font-weight: bold;
    color: black;
    background-color: #ffb347;
    border: #ffb347;
    padding: 0.75em 1.75em 0.75em 1.75em;
}

.x-mintButton:focus {
    background-color: #ffc36e;
    border: #ffb347;
    color: black;
}

.x-mintButton:hover {
    background-color: #ffb347;
    border: #ffb347;
    color: black;
}


.x-collection-img {
    display: flex;
    margin-top: auto;
}
.x-collection-img img {
    border-radius: 50%;
    width: 70px;
    /* height: 70px; */
    margin-right: 10px;
}
.x-collection-info p {
    margin: 0;
    justify-content: flex-start;
}

.x-collection-name {
    font-weight: bold;
    font-size: large;
}

.x-collection-minted {
    font-size:small;
}

.pop-chain-img {
    border-radius: 50px;
    /* width: 60px;
    height: 60px; */
    /* margin-bottom: 20px; */
    background-color: #3749E9;
    /* align-items: center; */
    margin: 0 auto auto 0;
    overflow: hidden;
    position: absolute;
}
.pop-chain-img img {
    width: 60px;
    height: 60px;
}

.pop-Right {
    text-align: end;
}

.pop-padding {
    padding: 22px !important;
}

.pop-title {
    color: #FFF;
    font-size: 30px;
    line-height: 40px;
}

.pop-text-4pin {
    width: 20px;
    margin: 5px;
    border-radius: 50px;
}


.pin-code { 
    padding: 0; 
    margin: 0 auto; 
    display: flex;
    justify-content:center;
} 
.pin-code input { 
    border: 1px solid rgb(247, 247, 247,0.8) !important;
    text-align: center; 
    /* width: 50px; */
    height:50px;
    font-size: 30px; 
    /* background-color: rgb(247, 247, 247,0.8) !important; */
    /* margin-right:5px; */
    border-radius: 20px !important;
    color: #FFF !important;
} 
.pin-code input:focus { 
    border: 1px solid #00BFFF !important;
    outline:none;
} 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* macOS */
.dock {
    width: auto;
    height: 60px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    /* position: absolute; */
    /* bottom: 20px; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* background-color: #3749E9; */
  
    .dock-container {
        padding: 3px;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        background: rgba(83, 83, 83, 0.25);
        backdrop-filter: blur(13px);
        -webkit-backdrop-filter: blur(13px);
        border: 1px solid rgba(255, 255, 255, 0.18);
  
        li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            vertical-align: bottom;
            transition: 0.2s;
            transform-origin: 50% 100%;
            margin: 0px 5px 0px 5px;
            
        .ico {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.2s;
          border-radius: 18px;
        }
        .ico-bin {
          width: 94% !important;
          height: 94% !important;
          object-fit: cover;
          transition: 0.2s;
        }
      }
    }
  }